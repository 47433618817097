import React, { useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import blackLogo from "../../../assets/images/teledeck-no-phrase.png";
import whiteLogo from "../../../assets/images/Teledeck.svg";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useFilter } from "../contexts/FilterContext";
import { useTheme } from "../../themes/ThemeContext";
import { useMapRef } from "../contexts/MapContext";

countries.registerLocale(enLocale);

const regions = {
  "Israel-Palestine": {
    countries: ["IL", "PS", "LB"],
    zoomLevel: 7,
  },
  "Ukraine-Russia": {
    countries: ["UA", "RU"],
    zoomLevel: 6,
  },
  Europe: {
    countries: ["FR", "DE", "IT", "ES", "GB"],
    zoomLevel: 5,
  },
};

const regionCenters = {
  "Israel-Palestine": [32.5, 35.5],
  "Ukraine-Russia": [50.746864, 39.96047],
  Europe: [49.170457, 10.809419],
};

const countryOptions = Object.keys(countries.getAlpha2Codes()).map((code) => ({
  value: code,
  label: countries.getName(code, "en"),
}));

const Navbar = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: ${({ theme }) => theme.column_background};
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
`;

const Logo = styled.img`
  height: 40px; /* Adjust the size of the logo as needed */
  margin-right: 20px;
  cursor: pointer;
`;

const RegionButton = styled.button`
  background-color: ${({ $isSelected, theme }) =>
    $isSelected ? " rgb(30, 40, 54)" : theme.button_background};
  color: ${({ $isSelected, theme }) =>
    $isSelected ? "#fff" : theme === "dark" ? "#bcb8cb" : "#000"};
  padding: 10px 15px;
  margin-right: 10px;
  border: 1px solid ${({ theme }) => theme.border_color};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: rgb(30, 40, 54);
    color: #fff;
  }
`;

const CountrySelectWrapper = styled.div`
  max-width: 800px;
`;

const RegionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NavBar = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { mapRef } = useMapRef();
  const { setCountries, setIsFreeView, isFreeView } = useFilter();
  const [selectedRegion, setSelectedRegion] = useState("Israel-Palestine");
  const [selectedCountries, setSelectedCountries] = useState(
    regions["Israel-Palestine"].countries.map((code) => ({
      value: code,
      label: countries.getName(code, "en"),
    }))
  );

  const handleRegionSelect = (region) => {
    setIsFreeView(false);
    setSelectedRegion(region);

    const selectedRegionData = regions[region];
    const newSelectedCountries = selectedRegionData.countries.map((code) => ({
      value: code,
      label: countries.getName(code, "en"),
    }));
    setSelectedCountries(newSelectedCountries);
    setCountries(newSelectedCountries.map((country) => country.value));

    if (mapRef.current) {
      const map = mapRef.current;
      const regionCenter = regionCenters[region];
      const zoomLevel = selectedRegionData.zoomLevel || 7;

      if (regionCenter) {
        map.setView(regionCenter, zoomLevel);
      }
    }
  };

  const handleCountryChange = (selectedOptions) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      toast.warning("Please select at least one country.", {
        duration: 3000,
        position: "bottom-center",
      });
      setSelectedRegion(null);
      return;
    }

    if (selectedOptions.length <= 5) {
      setSelectedCountries(selectedOptions || []);
      setIsFreeView(false);
      setCountries((selectedOptions || []).map((option) => option.value));

      if (
        selectedRegion &&
        !regions[selectedRegion].countries.every((code) =>
          (selectedOptions || []).some((option) => option.value === code)
        )
      ) {
        setSelectedRegion(null);
      }
    } else {
      toast.warning("You can only select up to 5 countries.", {
        duration: 3000,
        position: "bottom-center",
      });
    }
  };

  const handleFreeViewSelect = () => {
    setIsFreeView(true); // Enable free view mode
    setSelectedRegion(null); // Deselect any selected region
    setSelectedCountries([]); // Clear selected countries
    setCountries([]); // No countries in free view mode

    if (mapRef.current) {
      mapRef.current.setView([37.661909, 25.780937], 2);
    }
  };

  return (
    <Navbar>
      <RegionsWrapper>
        <Logo
          src={theme === "light" ? blackLogo : whiteLogo}
          alt="Logo"
          onClick={() => {
            navigate("/");
          }}
        />
        {Object.keys(regions).map((region) => (
          <RegionButton
            key={region}
            $isSelected={selectedRegion === region}
            onClick={() => handleRegionSelect(region)}
            theme={theme}
          >
            {region}
          </RegionButton>
        ))}
        <RegionButton
          $isSelected={isFreeView} // Check if free view is active
          onClick={() => {
            handleFreeViewSelect();
          }}
          theme={theme}
        >
          Free View
        </RegionButton>
      </RegionsWrapper>

      <CountrySelectWrapper>
        <Select
          isMulti
          options={countryOptions}
          value={selectedCountries}
          onChange={handleCountryChange}
          placeholder="Select countries..."
          isDisabled={isFreeView}
          styles={{
            control: (base, state) => ({
              ...base,
              fontSize: "14px",
              backgroundColor: theme === "dark" ? "#333" : "#fff",
            }),
            menu: (base) => ({
              ...base,
              backgroundColor: theme === "dark" ? "#333" : "#fff",
            }),
            singleValue: (base) => ({
              ...base,
              color: theme === "dark" ? "#fff" : "#000",
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused
                ? theme === "dark"
                  ? "#555"
                  : "#ddd"
                : theme === "dark"
                ? "#333"
                : "#fff",
              color: state.isFocused
                ? theme === "dark"
                  ? "#fff"
                  : "#000"
                : theme === "dark"
                ? "#fff"
                : "#000",
              cursor: "pointer",
            }),
            input: (base) => ({
              ...base,
              color: theme.text,
            }),
          }}
        />
      </CountrySelectWrapper>
    </Navbar>
  );
};

export default NavBar;
