import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "../components/authentication/auth-context/AuthContext.js";
import { openDb } from "../components/localdb/DBUtils.js";
import LoadingSpinner from "../global/loaders/AppLoader.js";
import Providers from "./Providers.js";
import Main from "./Main";
import AdminRoutes from "../admin/AdminRoutes.js";
import BookmarkEditor from "../components/side-panel/sidepanel-components/bookmark-editor/BookmarkEditor.js";
import Map from "../components/map/Map.js";
import SignIn from "../components/authentication/sign-in/SignIn.js";
import SignUp from "../components/authentication/sign-up/SignUp.js";
import ResetPassword from "../components/authentication/password-reset/passResetForm.js";

function App() {
  const { isLoading } = useAuth();

  useEffect(() => {
    const initializeDb = async () => {
      try {
        await openDb();
      } catch (error) {
        console.error("Error initializing database:", error);
      }
    };

    initializeDb();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <BrowserRouter>
      <Providers>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Main />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bookmark-editor"
            element={
              <ProtectedRoute>
                <BookmarkEditor />
              </ProtectedRoute>
            }
          />
          <Route
            path="/map"
            element={
              <BusinessRoutes>
                <Map />
              </BusinessRoutes>
            }
          />
          <Route
            path="/signin"
            element={
              <PublicRoute>
                <SignIn />
              </PublicRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <PublicRoute>
                <SignUp />
              </PublicRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/admin/*"
            element={
              <AdminRoute>
                <AdminRoutes />
              </AdminRoute>
            }
          />
        </Routes>
      </Providers>
    </BrowserRouter>
  );
}

function ProtectedRoute({ children }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/signin" replace />;
}

function PublicRoute({ children }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Navigate to="/" replace /> : children;
}

function AdminRoute({ children }) {
  const { role } = useAuth();
  return role === "admin" ? children : <Navigate to="/" replace />;
}

function BusinessRoutes({ children }) {
  const { role } = useAuth();
  return role === "customer" || role === "admin" ? (
    children
  ) : (
    <Navigate to="/" replace />
  );
}

export default App;
