import React from "react";
import MainMapComponent from "./map_components/MainMapComponent";
import { FilterProvider } from "./contexts/FilterContext";
import { MapProvider } from "./contexts/MapContext";
import SidePanelButton from "./side-panel/SidePanel_Button";
import NavBar from "./navbar/NavBar";
import { LocationsProvider } from "./contexts/LocationsContext";
import { ClickedPostProvider } from "./contexts/ClickedPostContext";

const Map = () => {
  return (
    <MapProvider>
      <LocationsProvider>
        <FilterProvider>
          <ClickedPostProvider>
            <MainMapComponent />
            <NavBar />
            <SidePanelButton />
          </ClickedPostProvider>
        </FilterProvider>
      </LocationsProvider>
    </MapProvider>
  );
};
export default Map;
